<template>
  <v-app>
    <v-container fluid fill-height>
      <v-row no-gutters>
        <!-- Store List on the Left -->
        <v-col cols="12" md="4" class="pa-4">
          <v-card outlined>
            <v-card-title class="headline outlined-col">
              <v-icon>mdi-store</v-icon>Store List
            </v-card-title>
            <br />
            <v-card-subtitle>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-subtitle>
            <v-list dense class="store-list">
              <v-divider></v-divider>
              <v-list-item-group v-model="selectedStoreId">
                <v-list-item
                  v-for="store in sortedStores"
                  :key="store.id"
                  @click="selectStore(store)"
                  class="store-list-item"
                >
                  <v-list-item-avatar>
                    <v-icon>mdi-store</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      class="font-weight-bold custom-text-color"
                      >{{ store.name }}</v-list-item-title
                    >
                    <v-list-item-subtitle class="blue--text text--darken-1">
                      {{ store.address }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle
                      v-if="store.distance"
                      class="mt-1 blue--text"
                    >
                      <v-icon small>mdi-clock-time-four</v-icon>
                      {{ store.distance.text }} ({{ store.duration.text }})
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>

        <!-- Map on the Right -->
        <v-col cols="12" md="8" class="pa-4">
          <v-card outlined>
            <v-card-title class="headline custom-text-color"
              >Stores Map</v-card-title
            >
            <v-col
              v-if="showFloatingCard"
              cols="12"
              md="12"
              class="pa-0 floating-card-col"
            >
              <v-card outlined class="floating-card">
                <v-card-subtitle class="font-weight-bold custom-text-color">
                  {{ directionsInfo.title }}
                </v-card-subtitle>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-card class="pa-3">
                        <v-row>
                          <v-col cols="auto" class="d-flex align-center">
                            <v-icon>mdi-map-marker</v-icon>
                          </v-col>
                          <v-col>
                            <p>
                              <strong>Distance:</strong>
                              {{ directionsInfo.distance }}
                            </p>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-card class="pa-3">
                        <v-row>
                          <v-col cols="auto" class="d-flex align-center">
                            <v-icon>mdi-clock</v-icon>
                          </v-col>
                          <v-col>
                            <p>
                              <strong>Duration:</strong>
                              {{ directionsInfo.duration }}
                            </p>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>

                  <v-row class="mt-3">
                    <v-col cols="12">
                      <v-card class="pa-3">
                        <p>
                          <strong>Start Address:</strong>
                          {{ directionsInfo.startAddress }}
                        </p>
                      </v-card>
                    </v-col>
                    <v-col cols="12">
                      <v-card class="pa-3">
                        <p>
                          <strong>End Address:</strong>
                          {{ directionsInfo.endAddress }}
                        </p>
                      </v-card>
                    </v-col>
                  </v-row>

                  <v-divider class="my-3"></v-divider>
                  <v-row>
                    <v-col cols="12">
                      <v-expansion-panels>
                        <v-expansion-panel>
                          <v-expansion-panel-title
                            >More details</v-expansion-panel-title
                          >
                          <v-expansion-panel-text>
                            <v-list dense>
                              <v-list-item-group
                                v-for="(step, index) in directionsInfo.steps"
                                :key="index"
                              >
                                <v-list-item>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      {{ index + 1 }}.
                                      <span
                                        v-html="step.htmlInstructions"
                                      ></span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                      ({{ step.distance }}, {{ step.duration }})
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list>
                          </v-expansion-panel-text>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="secondary" @click="showFloatingCard = false"
                    >Close</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-col>
            <v-card-text>
              <div id="map" style="height: 80"></div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Store Details Dialog -->
      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-img
            :width="full - width"
            aspect-ratio="16/9"
            cover
            :src="selectedStore.image"
          ></v-img>
          <v-card-title>
            <v-icon left>mdi-store</v-icon>
            {{ selectedStore.name }}
          </v-card-title>
          <v-card-subtitle>{{ selectedStore.address }}</v-card-subtitle>
          <v-card-text>
            <p><v-icon left>mdi-map-marker</v-icon> {{ selectedStore.type }}</p>
            <p>
              <v-icon left>mdi-information-outline</v-icon>
              {{ selectedStore.detail }}
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              @click="showDirections(selectedStore.lat, selectedStore.lng)"
            >
              Show Directions
            </v-btn>
            <v-btn color="secondary" @click="dialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";

export default {
  data() {
    return {
      map: null,
      dialog: false,
      search: "",
      directionsInfo: {
        title: "",
        distance: "",
        duration: "",
        startAddress: "",
        endAddress: "",
        steps: [], // An array of step-by-step instructions
      },
      selectedStore: {},
      selectedStoreId: null,
      stores: [], // Initially empty, will be filled with data from Google Sheets
      userLocation: null,
      showFloatingCard: false,
      directionsService: null,
      directionsRenderer: null,
      mapStyle: [
        {
          elementType: "geometry",
          stylers: [
            {
              color: "#f5f5f5",
            },
          ],
        },
        {
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#616161",
            },
          ],
        },
        {
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#f5f5f5",
            },
          ],
        },
        {
          featureType: "administrative.land_parcel",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "administrative.land_parcel",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#bdbdbd",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "geometry",
          stylers: [
            {
              color: "#eeeeee",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#757575",
            },
          ],
        },
        {
          featureType: "poi.business",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [
            {
              color: "#e5e5e5",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#757575",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [
            {
              color: "#dadada",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#616161",
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e",
            },
          ],
        },
        {
          featureType: "transit.line",
          elementType: "geometry",
          stylers: [
            {
              color: "#e5e5e5",
            },
          ],
        },
        {
          featureType: "transit.station",
          elementType: "geometry",
          stylers: [
            {
              color: "#eeeeee",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              color: "#c9c9c9",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e",
            },
          ],
        },
      ],
    };
  },
  computed: {
    filteredStores() {
      return this.stores.filter((store) =>
        store.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    sortedStores() {
      return this.filteredStores.slice().sort((a, b) => {
        if (this.selectedStoreId === a.id) return -1;
        if (this.selectedStoreId === b.id) return 1;
        return 0;
      });
    },
  },
  watch: {
    selectedStoreId(newId) {
      if (newId !== null) {
        this.selectedStore = this.stores.find((store) => store.id === newId);
        this.dialog = true;
      } else {
        this.dialog = false;
      }
    },
  },
  mounted() {
    this.loadMap();
    this.fetchStores();
  },
  methods: {
    loadMap() {
      const loader = new Loader({
        apiKey: "AIzaSyCJXBrR2A4ujw381xwxzI-mGNpn14vc1RA",
        version: "weekly",
      });

      loader.load().then(() => {
        this.map = new google.maps.Map(document.getElementById("map"), {
          center: { lat: 10.8231, lng: 106.6297 },
          zoom: 10,
          styles: this.mapStyle,
        });

        this.directionsService = new google.maps.DirectionsService();
        this.directionsRenderer = new google.maps.DirectionsRenderer();
        this.directionsRenderer.setMap(this.map);

        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };
              this.map.setCenter(pos);
              this.userLocation = pos;

              new google.maps.Marker({
                position: pos,
                map: this.map,
                icon: {
                  url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                },
              });

              this.calculateDistances();
            },
            () => {
              console.warn("Error: The Geolocation service failed.");
            }
          );
        } else {
          console.warn("Error: Your browser doesn't support geolocation.");
        }
      });
    },
    fetchStores() {
      const sheetId = "1mA_sHykM07BZDQZDKH0Iqq7KbasvYqYgSUA0CWCqwH8";
      const sheetName = "Sheet1";
      const apiKey = "AIzaSyDWVwpkzp7eCFzQypiY00zE-fVxw6wRnws";
      const url = `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${sheetName}?key=${apiKey}`;

      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          const rows = data.values;
          const headers = rows[0];
          this.stores = rows.slice(1).map((row) => {
            const store = {};
            headers.forEach((header, index) => {
              store[header.toLowerCase()] = row[index];
            });
            store.lat = parseFloat(store.lat);
            store.lng = parseFloat(store.lng);
            return store;
          });

          this.addMarkers();
        })
        .catch((error) => console.error("Error fetching stores:", error));
    },
    addMarkers() {
      this.stores.forEach((store) => {
        const marker = new google.maps.Marker({
          position: { lat: store.lat, lng: store.lng },
          map: this.map,
          icon: {
            url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
          },
        });

        marker.addListener("click", () => {
          this.selectStore(store);
        });
      });
    },
    selectStore(store) {
      this.selectedStoreId = store.id;
    },
    showDirections(lat, lng) {
      if (!this.userLocation) {
        alert("User location not available");
        return;
      }

      const request = {
        origin: this.userLocation,
        destination: { lat, lng },
        travelMode: google.maps.TravelMode.DRIVING,
      };

      this.directionsService.route(request, (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          this.directionsRenderer.setDirections(result);
          this.dialog = false;

          // Extracting route details
          const route = result.routes[0];
          const leg = route.legs[0];

          this.directionsInfo.title =
            "Directions to " + this.selectedStore.name;
          this.directionsInfo.distance = leg.distance.text;
          this.directionsInfo.duration = leg.duration.text;
          this.directionsInfo.startAddress = leg.start_address;
          this.directionsInfo.endAddress = leg.end_address;

          // Parsing steps for detailed directions
          this.directionsInfo.steps = leg.steps.map((step) => ({
            htmlInstructions: step.instructions,
            distance: step.distance.text,
            duration: step.duration.text,
          }));

          this.showFloatingCard = true;
        } else {
          console.error("Directions request failed due to " + status);
        }
      });
    },
    calculateDistances() {
      const service = new google.maps.DistanceMatrixService();
      const destinations = this.stores.map((store) => ({
        lat: store.lat,
        lng: store.lng,
      }));

      service.getDistanceMatrix(
        {
          origins: [this.userLocation],
          destinations,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === google.maps.DistanceMatrixStatus.OK) {
            const origins = response.originAddresses;
            const destinations = response.destinationAddresses;

            response.rows[0].elements.forEach((element, index) => {
              if (element.status === "OK") {
                this.$set(this.stores[index], "distance", element.distance);
                this.$set(this.stores[index], "duration", element.duration);
              } else {
                console.warn("Error: " + element.status);
              }
            });
          } else {
            console.warn("Distance Matrix request failed due to " + status);
          }
        }
      );
    },
  },
};
</script>

<style>
.outlined-col {
  background-color: #e0e0e0;
}
.store-list {
  max-height: 600px;
  overflow-y: auto;
}
.store-list-item {
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.store-list-item:hover {
  background-color: #f5f5f5;
}
.map {
  height: 100%;
  width: 100%;
}
.floating-card {
  background-color: #f9f9f9;
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  z-index: 999;
}
</style>
