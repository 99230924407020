// Styles
import '@mdi/font/css/materialdesignicons.css'; // Icon library
import 'vuetify/styles'; // Vuetify styles

// Vuetify
import { createVuetify } from 'vuetify';

// Create and export Vuetify instance with custom theme
const vuetify = createVuetify({
  theme: {
    themes: {
      light: {
        colors: {
          primary: '#1A237E', // Primary color
          secondary: '#388E3C', // Secondary color
          accent: '#8c9eff', // Accent color
          error: '#b71c1c', // Error color
        },
      },
      dark: {
        colors: {
          primary: '#1A237E',
          secondary: '#388E3C',
          accent: '#8c9eff',
          error: '#b71c1c',
        },
      },
    },
  },
});

export default vuetify;
