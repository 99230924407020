import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify'; // Import the Vuetify plugin
import { loadFonts } from './plugins/webfontloader';
import '@mdi/font/css/materialdesignicons.css'; // Ensure icons are imported
import 'vuetify/styles'; // Import Vuetify styles
import 'vuetify/dist/vuetify.min.css';

loadFonts();

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify) // Use Vuetify
  .mount('#app');
