<template>
  <v-app>
    <v-container fluid fill-height>
      <v-row no-gutters>
        <!-- Store List on the Left -->
        <v-col cols="12" md="4" class="pa-4">
          <v-card outlined>
            <v-card-title class="headline outlined-col">
              <v-icon>mdi-store</v-icon>Store List</v-card-title
            >
            <br />
            <v-card-subtitle>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-subtitle>
            <v-list dense class="store-list">
              <v-divider></v-divider>
              <v-list-item-group v-model="selectedStoreId">
                <v-list-item
                  v-for="store in sortedStores"
                  :key="store['Store ID']"
                  @click="selectStore(store)"
                  class="store-list-item"
                >
                  <v-list-item-avatar>
                    <v-icon>mdi-store</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      class="font-weight-bold custom-text-color"
                      >{{ store.Name }}</v-list-item-title
                    >
                    <v-list-item-subtitle class="blue--text text--darken-1">{{
                      store.Address
                    }}</v-list-item-subtitle>
                    <v-list-item-subtitle
                      v-if="store.distance"
                      class="mt-1 blue--text"
                    >
                      <v-icon small>mdi-clock-time-four</v-icon>
                      {{ store.distance.text }} ({{ store.duration.text }})
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>

        <!-- Map on the Right -->
        <v-col cols="12" md="8" class="pa-4">
          <v-card outlined>
            <v-card-title class="headline">Stores Map</v-card-title>
            <v-col v-if="showFloatingCard" cols="12" md="12" class="pa-0">
              <v-card outlined>
                <v-card-title>Directions Info</v-card-title>
                <v-card-subtitle>{{ directionsInfo.title }}</v-card-subtitle>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-card class="pa-3">
                        <v-row>
                          <v-col cols="auto" class="d-flex align-center">
                            <v-icon>mdi-map-marker</v-icon>
                          </v-col>
                          <v-col>
                            <p>
                              <strong>Distance:</strong>
                              {{ directionsInfo.distance }}
                            </p>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-card class="pa-3">
                        <v-row>
                          <v-col cols="auto" class="d-flex align-center">
                            <v-icon>mdi-clock</v-icon>
                          </v-col>
                          <v-col>
                            <p>
                              <strong>Duration:</strong>
                              {{ directionsInfo.duration }}
                            </p>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>

                  <v-row class="mt-3">
                    <v-col cols="12">
                      <v-card class="pa-3">
                        <p>
                          <strong>Start Address:</strong>
                          {{ directionsInfo.startAddress }}
                        </p>
                      </v-card>
                    </v-col>
                    <v-col cols="12">
                      <v-card class="pa-3">
                        <p>
                          <strong>End Address:</strong>
                          {{ directionsInfo.endAddress }}
                        </p>
                      </v-card>
                    </v-col>
                  </v-row>

                  <v-divider class="my-3"></v-divider>
                  <v-row>
                    <v-col cols="12">
                      <v-expansion-panels>
                        <v-expansion-panel>
                          <v-expansion-panel-title
                            >More details</v-expansion-panel-title
                          >
                          <v-expansion-panel-text>
                            <v-list dense>
                              <v-list-item-group
                                v-for="(step, index) in directionsInfo.steps"
                                :key="index"
                              >
                                <v-list-item>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      {{ index + 1 }}.
                                      <span
                                        v-html="step.htmlInstructions"
                                      ></span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                      ({{ step.distance }}, {{ step.duration }})
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list>
                          </v-expansion-panel-text>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="secondary" @click="showFloatingCard = false"
                    >Close</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-col>
            <v-card-text>
              <div id="map" style="height: 80"></div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Store Details Dialog -->
      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-img
            :width="full - width"
            aspect-ratio="16/9"
            cover
            :src="navagis_cover"
          ></v-img>
          <v-card-title>
            <v-icon left>mdi-store</v-icon>
            {{ selectedStore.Name }}
          </v-card-title>
          <v-card-subtitle>{{ selectedStore.Address }}</v-card-subtitle>
          <v-card-text>
            <p><v-icon left>mdi-phone</v-icon> {{ selectedStore.Phone }}</p>
            <p>
              <v-icon left>mdi-clock</v-icon>
              {{ selectedStore["Opening Hours"] }}
            </p>
            {{ selectedStore.Discription }}
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              @click="
                showDirections(selectedStore.Latitude, selectedStore.Longitude)
              "
            >
              Show Directions
            </v-btn>
            <v-btn color="secondary" @click="dialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";

export default {
  data() {
    return {
      map: null,
      dialog: false,
      search: "",
      directionsInfo: {
        title: "",
        distance: "",
        duration: "",
        startAddress: "",
        endAddress: "",
        steps: [], // An array of step-by-step instructions
      },
      selectedStore: {},
      selectedStoreId: null,
      stores: [], // Initially empty, will be filled with data from Google Sheets
      userLocation: null,
      showFloatingCard: false,
      directionsService: null,
      directionsRenderer: null,
      navagis_cover: require("@/assets/navagis_cover.jpeg"),
      mapStyle: [
        {
          elementType: "geometry",
          stylers: [
            {
              color: "#f5f5f5",
            },
          ],
        },
        {
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#616161",
            },
          ],
        },
        {
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#f5f5f5",
            },
          ],
        },
        {
          featureType: "administrative.land_parcel",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "administrative.land_parcel",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#bdbdbd",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "geometry",
          stylers: [
            {
              color: "#eeeeee",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#757575",
            },
          ],
        },
        {
          featureType: "poi.business",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [
            {
              color: "#e5e5e5",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#757575",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [
            {
              color: "#dadada",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#616161",
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e",
            },
          ],
        },
        {
          featureType: "transit.line",
          elementType: "geometry",
          stylers: [
            {
              color: "#e5e5e5",
            },
          ],
        },
        {
          featureType: "transit.station",
          elementType: "geometry",
          stylers: [
            {
              color: "#eeeeee",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              color: "#c9c9c9",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e",
            },
          ],
        },
      ],
    };
  },
  computed: {
    filteredStores() {
      if (!this.search) {
        return this.stores;
      }
      const searchLower = this.search.toLowerCase();
      return this.stores.filter((store) =>
        Object.values(store).some((value) =>
          String(value).toLowerCase().includes(searchLower)
        )
      );
    },
    sortedStores() {
      return this.filteredStores.sort((a, b) => {
        if (a.distance && b.distance) {
          return a.distance.value - b.distance.value;
        }
        return 0;
      });
    },
  },
  mounted() {
    const loader = new Loader({
      apiKey: "AIzaSyCJXBrR2A4ujw381xwxzI-mGNpn14vc1RA",
      version: "weekly",
    });

    loader.load().then(() => {
      this.fetchStores();
    });
  },
  methods: {
    async fetchStores() {
      try {
        const response = await fetch(
          "https://sheets.googleapis.com/v4/spreadsheets/17KavodstZqUc2i5hQJTXXhDO1Hd265YY9QchHYizns0/values/Sheet1?key=AIzaSyDWVwpkzp7eCFzQypiY00zE-fVxw6wRnws"
        );
        const data = await response.json();
        this.stores = this.formatStoreData(data.values);
        this.initMap();
      } catch (error) {
        console.error("Error fetching store data:", error);
      }
    },
    formatStoreData(data) {
      const headers = data[0];
      return data.slice(1).map((row) => {
        let store = {};
        headers.forEach((header, index) => {
          store[header.trim()] = row[index];
        });
        return store;
      });
    },
    initMap() {
      this.map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: 10.7769, lng: 106.7009 },
        zoom: 12,
        styles: this.mapStyle,
      });

      this.directionsService = new google.maps.DirectionsService();
      this.directionsRenderer = new google.maps.DirectionsRenderer();
      this.directionsRenderer.setMap(this.map);
      const storeIconUrl =
        "https://img.icons8.com/?size=30&id=pB52ww0LIf3q&format=png&color=000000";

      this.stores.forEach((store) => {
        const marker = new google.maps.Marker({
          position: {
            lat: parseFloat(store.Latitude),
            lng: parseFloat(store.Longitude),
          },
          map: this.map,
          icon: storeIconUrl,
          title: store.Name,
        });

        marker.addListener("click", () => {
          this.selectStore(store);
        });
      });

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.userLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          const userMarker = new google.maps.Marker({
            position: this.userLocation,
            map: this.map,
            title: "Your Location",
            icon: {
              url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
            },
            animation: google.maps.Animation.BOUNCE,
          });
          console.error(this.userLocation);
          this.map.setCenter(this.userLocation);
          this.calculateDistances();
        });
      }
    },
    calculateDistances() {
      const service = new google.maps.DistanceMatrixService();
      const destinations = this.stores.map((store) => ({
        lat: parseFloat(store.Latitude),
        lng: parseFloat(store.Longitude),
      }));

      service.getDistanceMatrix(
        {
          origins: [this.userLocation],
          destinations: destinations,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === google.maps.DistanceMatrixStatus.OK) {
            const results = response.rows[0].elements;
            this.stores = this.stores.map((store, index) => ({
              ...store,
              distance: results[index].distance,
              duration: results[index].duration,
            }));
          }
        }
      );
    },
    showDirections(lat, lng) {
      this.directionsService.route(
        {
          origin: this.userLocation,
          destination: { lat: parseFloat(lat), lng: parseFloat(lng) },
          travelMode: google.maps.TravelMode.DRIVING,
          language: "vi",
        },
        (response, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            this.directionsRenderer.setDirections(response);
            const route = response.routes[0].legs[0];
            this.directionsInfo = {
              title: `To ${this.selectedStore.Name}`,
              distance: route.distance.text,
              duration: route.duration.text,
              startAddress: route.start_address,
              endAddress: route.end_address,
              steps: route.steps.map((step) => ({
                distance: step.distance.text,
                duration: step.duration.text,
                htmlInstructions: step.instructions,
              })),
            };
            this.showFloatingCard = true; // Show floating card
            this.dialog = false;
            console.log(response.routes[0].legs[0].steps);
          } else {
            console.error("Directions request failed due to " + status);
          }
        }
      );
    },
    selectStore(store) {
      this.selectedStore = store;
      this.dialog = true;
    },
  },
};
</script>

<style>
#map {
  width: 100%;
  height: 80vh;
}
.pa-4 {
  padding: 16px;
}
.v-list-item {
  cursor: pointer;
}
/* .v-card {
  height: 100%;
} */
.store-list {
  max-height: 80vh;
  overflow-y: auto;
}
.store-list-item {
  border-bottom: 1px solid #e0e0e0;
}
.store-list-item:hover {
  background-color: #e3f2fd;
}
/* .floating-card {
  position: relative;
  padding: 16px;
} */

.outlined-col {
  border: 1px solid #3f00ff; /* Adjust the border color and width as needed */
  border-radius: 1px; /* Optional: Add border radius for rounded corners */
}
.custom-text-color {
  color: #3f00ff !important;
}
</style>
