<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      app
      color="primary"
      dark
      dense
      class="white--text"
      bold
      small
    >
      <!-- :expand-on-hover="expandOnHover" -->
      <v-list dense nav class="py-0">
        <v-list-item two-line class="px-0">
          <v-list-item-content>
            <v-list-item-title>Nguyễn Văn Tín</v-list-item-title>
            <v-list-item-subtitle
              >Email: nguyen.vantin@navagis.com</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.link"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app color="primary" dark dense class="white--text" bold small>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-list-item-avatar>
        <img :src="logo" width="50" height="50" />
      </v-list-item-avatar>
      <v-toolbar-title>NAVAGIS VIỆT NAM - LOCATION INTELLIGENT</v-toolbar-title>
      <v-spacer />

      <template v-if="!isAuthenticated">
        <v-btn text @click="goToLogin">Login</v-btn>
        <v-btn text @click="goToRegister">Register</v-btn>
      </template>

      <template v-else>
        <v-menu
          v-model:active="userMenu"
          offset-y
          :close-on-content-click="false"
        >
          <template #activator="{ props }">
            <v-btn text v-bind="props" class="d-inline-flex align-center">
              <v-avatar size="40" class="logo">
                <span class="logo-text">{{ userName.charAt(0) }}</span>
              </v-avatar>
              <!-- <span class="ml-2">{{ userName }}</span> -->
            </v-btn>
          </template>

          <!-- Logout Button -->
          <v-list>
            <v-list-item @click="logout">
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <!-- Color Picker Menu -->
      <!-- <v-menu
        v-model:active="menu"
        offset-y
        close-on-content-click
        transition="scale-transition"
      >
        <template #activator="{ props }">
          <v-btn v-bind="props" icon>
            <v-icon>mdi-palette</v-icon>
          </v-btn>
        </template>

        <v-btn
          v-for="color in colors"
          :key="color"
          :style="{ backgroundColor: color, color: 'white' }"
          @click="changePrimaryColor(color)"
          class="ma-2"
        >
          {{ color }}
        </v-btn>
      </v-menu> -->
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      logo: require("@/assets/navagis.png"),
      color: "#1A237E", // Default color
      menu: false, // Control the menu visibility
      colors: [
        "#1A237E", // Indigo
        "#ff5722", // Deep Orange
        "#4caf50", // Green
        "#2196f3", // Blue
        "#f44336", // Red
        "#9c27b0", // Purple
        "#ff9800", // Orange
        "#00bcd4", // Cyan
        "#795548", // Brown
        "#009688", // Teal
      ],
      drawer: false,
      expandOnHover: true,
      userMenu: false,
      showLoginDialog: false,
      showRegisterDialog: false,
      items: [
        { title: "Trang chủ", icon: "mdi-home", link: "/" },
        { title: "Store Locator", icon: "mdi-radar", link: "/storelocator" },
        { title: "Autocomplete", icon: "mdi-fire", link: "/autocomplete" },
        { title: "SmartTourism", icon: "mdi-fire", link: "/smarttourism" },

        // { title: "Mobility", icon: "mdi-fire", link: "/test" },
      ],
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated", "getUser"]),
    userName() {
      return this.getUser ? this.getUser.username : "";
    },
  },
  methods: {
    ...mapActions(["logout"]),
    handleLogout() {
      this.logout().then(() => {
        this.$router.push("/"); // Redirect to home after logout
      });
    },
    updatePrimaryColor(newColor) {
      this.color = newColor;
      this.saveColorToLocalStorage(newColor);
    },
    changePrimaryColor(newColor) {
      console.log(`Changing color to: ${newColor}`);
      this.$vuetify.theme.themes.light.primary = newColor;
      this.updatePrimaryColor(newColor);
    },
    saveColorToLocalStorage(color) {
      console.log(`Saving color ${color} to localStorage`);
      localStorage.setItem("primaryColor", color);
    },
    getSavedColor() {
      const savedColor = localStorage.getItem("primaryColor");
      console.log(`Retrieved color from localStorage: ${savedColor}`);
      return savedColor;
    },
    goToLogin() {
      this.$router.push("/login"); // Redirect to login page
    },
    goToRegister() {
      this.$router.push("/register"); // Redirect to register page
    },
  },
  mounted() {
    // console.log(theme.global.value.light.primary);
    const savedColor = this.getSavedColor();
    if (savedColor) {
      this.color = savedColor;
      this.$vuetify.theme.themes.light.primary = "red";
    }
  },
};
</script>

<style scoped>
.color-picker-item {
  cursor: pointer;
  min-height: 48px; /* Increase item height */
  padding: 4px; /* Add padding */
  margin-bottom: 8px; /* Add space between items */
}

.color-picker-text {
  font-size: 0.875rem; /* Adjust font size */
  color: white; /* Ensure text is readable */
}
.v-app-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2000;
}

.v-app-bar-nav-icon {
  cursor: pointer;
}

.v-toolbar-title {
  font-weight: bold;
}

.v-app-bar .v-btn {
  margin-right: 12px;
}

.v-menu {
  margin-top: 10px;
}
.logo {
  background-color: rgb(137, 11, 227); /* Set your desired background color */
  color: white; /* Text color */
  font-size: 10px; /* Adjust font size */
  font-weight: bold; /* Bold text */
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-text {
  text-transform: uppercase; /* Convert text to uppercase */
}
</style>
