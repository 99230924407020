import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../components/views/HomeView.vue'
import StoreLocator from '../components/views/StoreLocator.vue'
import Autocomplete from '../components/views/Autocomplete.vue'
import SmartTourism from '../components/views/SmartTourism.vue'
import Login from '../components/auth/Login.vue'
import Register from '../components/auth/Register.vue'




const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/storelocator',
    name: 'storelocator',
    component: StoreLocator
  },
  {
    path: '/autocomplete',
    name: 'autocomplete',
    component: Autocomplete
  },
  {
    path: '/smarttourism',
    name: 'SmartTourism',
    component: SmartTourism
  },
    // Authentication Routes
    { path: '/login', name: 'Login', component: Login },
    { path: '/register', name: 'Register', component: Register },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
