<template>
  <v-container fluid fill-height>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="8" md="4">
        <v-card>
          <v-img
            src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
            height="200px"
          >
            <v-card-title class="white--text headline">Login</v-card-title>
          </v-img>
          <v-card-subtitle class="pt-0">
            <v-form ref="form" @submit.prevent="handleLogin" v-model="valid">
              <v-text-field
                v-model="username"
                label="Username"
                :rules="[rules.required]"
                required
              ></v-text-field>
              <v-text-field
                v-model="password"
                label="Password"
                type="password"
                :rules="[rules.required]"
                required
              ></v-text-field>
              <v-btn type="submit" color="primary" class="mt-4">Login</v-btn>
              <v-alert
                v-if="showErrorMessage"
                type="error"
                class="mt-3"
                :style="{ opacity: errorOpacity }"
              >
                {{ errorMessage }}
              </v-alert>
              <v-alert
                v-if="showSuccessMessage"
                type="success"
                class="mt-3"
                :style="{ opacity: successOpacity }"
              >
                {{ successMessage }}
              </v-alert>
            </v-form>
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      username: "",
      password: "",
      valid: false,
      errorMessage: "",
      successMessage: "",
      showErrorMessage: false,
      showSuccessMessage: false,
      errorOpacity: 1,
      successOpacity: 1,
      rules: {
        required: (value) => !!value || "This field is required.",
      },
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  watch: {
    isAuthenticated(val) {
      if (val) {
        this.successMessage = "You are already logged in! Redirecting...";
        this.showSuccessMessage = true;
        this.startFadeOut("success");
        setTimeout(() => {
          this.$router.push("/"); // Redirect after 5 seconds
        }, 3000); // Redirect after 5 seconds
      }
    },
  },
  methods: {
    ...mapActions(["login"]),
    async handleLogin() {
      if (this.$refs.form.validate()) {
        try {
          await this.login({
            username: this.username,
            password: this.password,
          });
          this.successMessage = "Login successful! Redirecting...";
          this.showSuccessMessage = true;
          this.showErrorMessage = false;
          this.startFadeOut("success");
          setTimeout(() => {
            this.$router.push("/"); // Redirect after 5 seconds
          }, 5000); // Redirect after 5 seconds
        } catch (error) {
          this.errorMessage = error.response
            ? error.response.data.detail
            : error.message;
          this.successMessage = "";
          this.showErrorMessage = true;
          this.showSuccessMessage = false;
          this.startFadeOut("error");
        }
      } else {
        this.errorMessage = "Please correct the errors above.";
        this.successMessage = "";
        this.showErrorMessage = true;
        this.showSuccessMessage = false;
        this.startFadeOut("error");
      }
    },
    startFadeOut(type) {
      const interval = 50;
      const duration = 3000; // Duration for fade out
      const steps = duration / interval;
      const fadeStep = 1 / steps;

      const opacityKey = type === "success" ? "successOpacity" : "errorOpacity";
      const showKey =
        type === "success" ? "showSuccessMessage" : "showErrorMessage";

      let currentStep = 0;

      const fadeInterval = setInterval(() => {
        if (currentStep >= steps) {
          clearInterval(fadeInterval);
          this[showKey] = false;
          this[opacityKey] = 1;
        } else {
          this[opacityKey] = 1 - fadeStep * currentStep;
          currentStep++;
        }
      }, interval);
    },
  },
  mounted() {
    if (this.isAuthenticated) {
      this.successMessage = "You are already logged in! Redirecting...";
      this.showSuccessMessage = true;
      this.startFadeOut("success");
      setTimeout(() => {
        this.$router.push("/"); // Redirect after 5 seconds
      }, 5000); // Redirect after 5 seconds
    }
  },
};
</script>

<style scoped>
.v-card {
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.v-card-title {
  font-weight: bold;
}

.v-text-field {
  margin-bottom: 16px;
}

.v-btn {
  width: 100%;
}
</style>
