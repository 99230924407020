<template>
  <v-container fluid>
    <v-row>
      <!-- Address Details Column -->
      <v-col cols="12" md="4" class="d-flex flex-column pa-3">
        <div class="address-details-container">
          <!-- Location Input with Google Autocomplete -->
          <v-card class="search-box">
            <v-text-field
              v-model="address"
              label="Enter your address"
              outlined
              ref="autocompleteInput"
              @focus="initAutocomplete"
            ></v-text-field>
          </v-card>

          <!-- Address Details -->
          <v-card v-if="showDetails" class="pa-3 mb-3 flex-grow-3">
            <v-card-title> Address Details </v-card-title>
            <v-card-subtitle class="json-output">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-form>
                    <v-text-field
                      label="Formatted Address"
                      v-model="formattedAddress"
                      readonly
                      outlined
                    ></v-text-field>
                    <v-text-field
                      label="Place ID"
                      v-model="placeId"
                      readonly
                      outlined
                    ></v-text-field>
                    <v-text-field
                      label="Street Number"
                      v-model="streetNumber"
                      readonly
                      outlined
                    ></v-text-field>
                    <v-text-field
                      label="Route"
                      v-model="route"
                      readonly
                      outlined
                    ></v-text-field>
                    <v-text-field
                      label="Commune"
                      v-model="commune"
                      readonly
                      outlined
                    ></v-text-field>
                    <v-text-field
                      label="Postcode"
                      v-model="postcode"
                      readonly
                      outlined
                    ></v-text-field>
                  </v-form>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-form>
                    <v-text-field
                      label="Country"
                      v-model="country"
                      readonly
                      outlined
                    ></v-text-field>
                    <v-text-field
                      label="State"
                      v-model="state"
                      readonly
                      outlined
                    ></v-text-field>
                    <v-text-field
                      label="City"
                      v-model="city"
                      readonly
                      outlined
                    ></v-text-field>
                    <v-text-field
                      label="District"
                      v-model="district"
                      readonly
                      outlined
                    ></v-text-field>

                    <v-text-field
                      label="Latitude"
                      v-model="latitude"
                      readonly
                      outlined
                    ></v-text-field>
                    <v-text-field
                      label="Longitude"
                      v-model="longitude"
                      readonly
                      outlined
                    ></v-text-field>
                  </v-form>
                </v-col>
              </v-row>
            </v-card-subtitle>
          </v-card>

          <!-- JSON Output -->
          <v-card v-if="placeDetails" class="pa-3 flex-grow-6">
            <v-card-title> Place Details (JSON) </v-card-title>
            <v-card-subtitle>
              <pre class="json-output">{{ formatJson(placeDetails) }}</pre>
            </v-card-subtitle>
          </v-card>
        </div>
      </v-col>

      <!-- Map Column -->
      <v-col cols="12" md="8" class="pa-3">
        <div id="map" class="map-container"></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      address: "",
      showDetails: false,
      autocomplete: null,
      placeDetails: null,
      map: null,
      googleApiKey: "AIzaSyCJXBrR2A4ujw381xwxzI-mGNpn14vc1RA",
    };
  },
  computed: {
    // ...mapGetters(["googleApiKey"]),
    formattedAddress() {
      return this.placeDetails ? this.placeDetails.formatted_address : "";
    },
    placeId() {
      return this.placeDetails ? this.placeDetails.place_id : "N/A";
    },
    country() {
      return this.getAddressComponent("country");
    },
    state() {
      return this.getAddressComponent("administrative_area_level_1");
    },
    city() {
      return this.getAddressComponent("locality");
    },
    district() {
      return this.getAddressComponent("administrative_area_level_2");
    },
    commune() {
      return this.getAddressComponent("sublocality");
    },
    postcode() {
      return this.getAddressComponent("postal_code");
    },
    streetNumber() {
      return this.getAddressComponent("street_number");
    },
    route() {
      return this.getAddressComponent("route");
    },
    latitude() {
      return this.placeDetails && this.placeDetails.geometry
        ? this.placeDetails.geometry.location.lat()
        : "N/A";
    },
    longitude() {
      return this.placeDetails && this.placeDetails.geometry
        ? this.placeDetails.geometry.location.lng()
        : "N/A";
    },
  },
  methods: {
    initAutocomplete() {
      if (this.autocomplete) return; // Initialize only once

      const input = this.$refs.autocompleteInput.$el.querySelector("input");
      this.autocomplete = new google.maps.places.Autocomplete(input, {
        types: ["geocode"], // Restrict results to geographical location
      });

      this.autocomplete.addListener("place_changed", () => {
        const place = this.autocomplete.getPlace();
        if (place.geometry) {
          this.address = place.formatted_address;
          this.showDetails = true; // Show checkout details
          this.placeDetails = place; // Store place details for JSON output
          this.updateMap(place.geometry.location); // Update map with selected location
        }
      });
    },
    handleCheckout() {
      // Simulate a checkout process
      alert(`Checkout confirmed for location: ${this.address}`);
    },
    updateMap(location) {
      if (this.map) {
        this.map.setCenter(location);
        new google.maps.Marker({
          position: location,
          map: this.map,
        });
      }
    },
    initializeMap() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const userLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          this.map = new google.maps.Map(document.getElementById("map"), {
            center: userLocation,
            zoom: 15,
          });

          new google.maps.Marker({
            position: userLocation,
            map: this.map,
            title: "Your Location",
          });
        });
      }
    },
    getAddressComponent(type) {
      if (!this.placeDetails || !this.placeDetails.address_components)
        return "N/A";
      const component = this.placeDetails.address_components.find((comp) =>
        comp.types.includes(type)
      );
      return component ? component.long_name : "N/A";
    },
    formatJson(obj) {
      return JSON.stringify(obj, null, 2);
    },
  },
  mounted() {
    if (!window.google) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${this.googleApiKey}&libraries=places`;
      script.onload = () => {
        this.initializeMap();
        this.initAutocomplete();
      };
      document.head.appendChild(script);
    } else {
      this.initializeMap();
      this.initAutocomplete();
    }
  },
};
</script>

<style scoped>
.address-details-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.json-output {
  max-height: 40vh; /* Adjust height as needed */
  overflow-y: auto; /* Enable scrolling */
  white-space: pre-wrap; /* Preserve whitespace formatting */
}
.map-container {
  max-height: 100vh;
}
.search-box {
  max-height: 100px; /* Adjust height as needed */
  overflow-y: auto; /* Enable scrolling */
  white-space: pre-wrap; /* Preserve whitespace formatting */
}

.v-card {
  overflow: hidden; /* Ensure content does not overflow card */
}
</style>
