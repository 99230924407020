<template>
  <v-app>
    <v-container fluid>
      <!-- Parallax Section -->
      <v-parallax
        height="300"
        src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg"
      >
        <div
          class="d-flex flex-column fill-height justify-center align-center text-white"
        >
          <h1 class="text-h4 font-weight-thin mb-4">NAVAGIS</h1>
          <h4 class="subheading">
            Bringing location intelligence to your organization!
          </h4>
        </div>
      </v-parallax>

      <!-- Divider -->
      <v-divider></v-divider>

      <!-- Introduction Section -->
      <v-container fluid class="indigo my-5 py-5">
        <v-row justify="center">
          <v-col cols="12" md="8">
            <v-card class="pa-3" outlined>
              <v-card-title class="text-h5">Về Chúng Tôi</v-card-title>
              <v-card-subtitle>
                Tìm hiểu thêm về Navagis và sứ mệnh của chúng tôi trong việc
                cung cấp các giải pháp thông minh về địa điểm.
              </v-card-subtitle>
              <v-card-text>
                Navagis là công ty hàng đầu thế giới trong lĩnh vực giải pháp
                thông minh về địa điểm, giúp các tổ chức chuyển đổi hoạt động
                kinh doanh thông qua khám phá dữ liệu, học máy và hình ảnh hóa.
                Với hơn 20 năm kinh nghiệm, chúng tôi đã cung cấp giải pháp cho
                nhiều thách thức kinh doanh độc đáo của các doanh nghiệp lớn
                trong nhiều ngành công nghiệp khác nhau trên toàn thế giới.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <!-- Filter Section -->
      <v-container fluid class="my-5">
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="selectedTag"
              :items="availableTags"
              label="Filter by hashtag"
              clearable
              outlined
            ></v-select>
          </v-col>
        </v-row>
      </v-container>

      <!-- Photo Cards Section -->
      <v-container fluid>
        <v-row>
          <v-col
            cols="12"
            md="6"
            lg="3"
            v-for="(item, index) in filteredItems"
            :key="index"
          >
            <v-card class="hover-card">
              <v-img :src="item.src" class="card-image"></v-img>
              <v-card-title>{{ item.title }}</v-card-title>
              <v-card-subtitle>{{ item.subtitle }}</v-card-subtitle>
              <v-card-text>
                {{ item.text }}
              </v-card-text>
              <v-card-actions>
                <v-btn :href="item.link" color="primary">Xem chi tiết</v-btn>
              </v-card-actions>
              <v-card-actions>
                <v-chip
                  v-for="(tag, index) in item.tags"
                  :key="index"
                  class="ma-1"
                  small
                  >{{ tag }}</v-chip
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <!-- Footer Section -->
      <v-footer color="primary" class="white--text">
        <v-container>
          <v-row align="center" justify="center">
            <v-col cols="12" md="12">
              <!-- Logo Section -->
              <p align="center" justify="center">
                © 2024 NAVAGIS. All Rights Reserved.
              </p>
              <v-img :src="logo" contain height="60"></v-img>
            </v-col>
          </v-row>
          <v-row mt-4 align="center" justify="center">
            <v-col align="center" justify="center" cols="12" md="12">
              <v-btn
                href="mailto:info@navagis.com"
                text
                class="white--text mx-2"
                >Contact Us</v-btn
              >
              <v-btn href="#" text class="white--text mx-2"
                >Privacy Policy</v-btn
              >
              <v-btn href="#" text class="white--text mx-2"
                >Terms of Service</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
    </v-container>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      selectedTag: null,
      logo: require("@/assets/navagis.png"),
      items: [
        {
          src: require("@/assets/storelocator.jpg"),
          title: "Giải pháp Store Locator",
          subtitle: "Hiển thị danh sách cửa hàng",
          text: "Giải pháp hiển thị danh sách và tìm đường tới các cửa hàng",
          link: "/storelocator",
          tags: ["#Store", "#Maps", "#Solution"],
        },
        {
          src: require("@/assets/autocomplete.jpg"),
          title: "Sử dụng Autocomplete",
          subtitle: "Hỗ trợ gợi ý nhanh nhập tên địa điểm",
          text: "Giải pháp tự động tìm kiếm địa điểm hỗ trợ tiết kiệm thời gian",
          link: "/autocomplete",
          tags: ["#Places", "#Maps", "#API"],
        },
        {
          src: "https://cdn.vuetifyjs.com/images/cards/forest.jpg",
          title: "Card 3",
          subtitle: "Subtitle 3",
          text: "This is a brief description of the third card.",
          link: "#link3",
          tags: ["#forest", "#nature"],
        },
        {
          src: "https://cdn.vuetifyjs.com/images/cards/mountain.jpg",
          title: "Card 4",
          subtitle: "Subtitle 4",
          text: "This is a brief description of the fourth card.",
          link: "#link4",
          tags: ["#mountain", "#adventure"],
        },
        {
          src: "https://cdn.vuetifyjs.com/images/cards/desert.jpg",
          title: "Card 1",
          subtitle: "Subtitle 1",
          text: "This is a brief description of the first card.",
          link: "#link1",
          tags: ["#desert", "#nature"],
        },
        {
          src: "https://cdn.vuetifyjs.com/images/cards/plane.jpg",
          title: "Card 2",
          subtitle: "Subtitle 2",
          text: "This is a brief description of the second card.",
          link: "#link2",
          tags: ["#plane", "#travel"],
        },
        {
          src: "https://cdn.vuetifyjs.com/images/cards/forest.jpg",
          title: "Card 3",
          subtitle: "Subtitle 3",
          text: "This is a brief description of the third card.",
          link: "#link3",
          tags: ["#forest", "#nature"],
        },
        {
          src: "https://cdn.vuetifyjs.com/images/cards/mountain.jpg",
          title: "Card 4",
          subtitle: "Subtitle 4",
          text: "This is a brief description of the fourth card.",
          link: "#link4",
          tags: ["#mountain", "#adventure"],
        },
      ],
    };
  },
  computed: {
    availableTags() {
      const tags = new Set();
      this.items.forEach((item) => {
        item.tags.forEach((tag) => tags.add(tag));
      });
      return Array.from(tags);
    },
    filteredItems() {
      if (!this.selectedTag) {
        return this.items;
      }
      return this.items.filter((item) => item.tags.includes(this.selectedTag));
    },
  },
};
</script>
<style scoped>
.indigo {
  background-color: #3f51b5 !important;
  color: white;
}
.v-footer {
  background-color: #f5f5f5;
  padding: 20px;
}
.card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.hover-card {
  transition: transform 0.2s, box-shadow 0.2s;
}
.hover-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
</style>
