<template>
  <v-container class="d-flex justify-center">
    <v-card class="pa-4" max-width="500">
      <v-card-title class="text-h5">Register</v-card-title>
      <v-img
        src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
        height="200px"
        class="mb-4"
      ></v-img>
      <v-form ref="form" @submit.prevent="register">
        <v-text-field
          v-model="username"
          label="Username"
          required
          :rules="usernameRules"
        />
        <small class="text-muted">
          Username must be at least 6 characters long, contain only alphanumeric
          characters, no spaces, and not be entirely numeric.
        </small>
        <v-text-field
          v-model="password"
          type="password"
          label="Password"
          required
          :rules="passwordRules"
        />
        <small class="text-muted">
          Password must be at least 8 characters long, contain at least one
          digit, one uppercase letter, one lowercase letter, and one special
          character.
        </small>
        <v-text-field
          v-model="confirmPassword"
          type="password"
          label="Confirm Password"
          required
          :rules="confirmPasswordRules"
        />
        <v-btn type="submit" color="primary" class="mt-4" block>
          Register
        </v-btn>
        <v-alert
          v-if="showSuccessMessage"
          type="success"
          class="mt-4"
          :style="{ opacity: successOpacity }"
        >
          {{ successMessage }}
        </v-alert>
        <v-alert
          v-if="showError"
          type="error"
          class="mt-4"
          :style="{ opacity: errorOpacity }"
        >
          {{ error }}
        </v-alert>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      password: "",
      confirmPassword: "",
      error: null,
      successMessage: null,
      showSuccessMessage: false,
      showError: false,
      successOpacity: 1,
      errorOpacity: 1,
      usernameRules: [
        (v) => !!v || "Username is required",
        (v) => v.length >= 6 || "Username must be at least 6 characters",
        (v) =>
          /^[a-zA-Z0-9]+$/.test(v) ||
          "Username must contain only alphanumeric characters",
        (v) => !/\s/.test(v) || "Username must not contain spaces",
        (v) => !/^\d+$/.test(v) || "Username must not be entirely numeric",
      ],
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => v.length >= 8 || "Password must be at least 8 characters",
        (v) => /[0-9]/.test(v) || "Password must contain at least one digit",
        (v) =>
          /[A-Z]/.test(v) ||
          "Password must contain at least one uppercase letter",
        (v) =>
          /[a-z]/.test(v) ||
          "Password must contain at least one lowercase letter",
        (v) =>
          /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(v) ||
          "Password must contain at least one special character",
      ],
      confirmPasswordRules: [
        (v) => !!v || "Confirmation is required",
        (v) => v === this.password || "Passwords must match",
      ],
    };
  },
  methods: {
    async register() {
      if (!this.$refs.form.validate()) {
        this.error = "Please fix the errors in the form.";
        this.successMessage = null;
        this.showSuccessMessage = false;
        this.showError = true;
        this.startFadeOut("error");
        return;
      }

      try {
        await this.$store.dispatch("register", {
          username: this.username,
          password: this.password,
        });
        this.successMessage = "Registration successful! Redirecting...";
        this.error = null;
        this.showSuccessMessage = true;
        this.showError = false;
        this.startFadeOut("success");
        setTimeout(() => {
          this.$router.push("/"); // Redirect after successful registration
        }, 3000); // Redirect after 3 seconds
      } catch (err) {
        this.error = err.response
          ? err.response.data.detail
          : "Registration failed. Please try again.";
        this.successMessage = null;
        this.showSuccessMessage = false;
        this.showError = true;
        this.startFadeOut("error");
      }
    },
    startFadeOut(type) {
      const interval = 50;
      const duration = 3000; // Duration for fade out
      const steps = duration / interval;
      const fadeStep = 1 / steps;

      const opacityKey = type === "success" ? "successOpacity" : "errorOpacity";
      const showKey = type === "success" ? "showSuccessMessage" : "showError";

      let currentStep = 0;

      const fadeInterval = setInterval(() => {
        if (currentStep >= steps) {
          clearInterval(fadeInterval);
          this[showKey] = false;
          this[opacityKey] = 1;
        } else {
          this[opacityKey] = 1 - fadeStep * currentStep;
          currentStep++;
        }
      }, interval);
    },
  },
};
</script>

<style scoped>
.v-alert {
  transition: opacity 0.5s ease;
}
.text-muted {
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.6);
}
</style>
