import { createStore } from 'vuex';
import axios from 'axios';

const store = createStore({
  state() {
    return {
      accessToken: localStorage.getItem('accessToken') || null,
      refreshToken: localStorage.getItem('refreshToken') || null,
      user: JSON.parse(localStorage.getItem('user')) || null,
      domainName: 'http://localhost:8000/', // Your API base URL
      googleApiKey: 'AIzaSyCJXBrR2A4ujw381xwxzI-mGNpn14vc1RA', // API Key
    };
  },
  mutations: {
    SET_TOKENS(state, { access, refresh }) {
      state.accessToken = access;
      state.refreshToken = refresh;
      localStorage.setItem('accessToken', access);
      localStorage.setItem('refreshToken', refresh);
    },
    SET_USER(state, user) {
      state.user = user;
      localStorage.setItem('user', JSON.stringify(user));
    },
    LOGOUT(state) {
      state.accessToken = null;
      state.refreshToken = null;
      state.user = null;
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('user');
    },
  },
  actions: {
    async login({ commit, state }, { username, password }) {
      try {
        const response = await axios.post(`${state.domainName}api/token/`, {
          username,
          password
        });
        commit('SET_TOKENS', {
          access: response.data.access,
          refresh: response.data.refresh
        });
        await this.dispatch('fetchUser');
      } catch (error) {
        console.error('Login failed', error.response ? error.response.data : error.message);
        throw error;
      }
    },
    
    async register({ commit, state }, { username, password }) {
      try {
        const response = await axios.post(`${state.domainName}api/register/`, {
          username,
          password
        });
        await this.dispatch('login', { username, password });
      } catch (error) {
        console.error('Registration failed', error.response ? error.response.data : error.message);
        throw error;
      }
    },
    
    async fetchUser({ commit, state }) {
      try {
        const response = await axios.get(`${state.domainName}api/user/`, {
          headers: {
            Authorization: `Bearer ${state.accessToken}`
          }
        });
        commit('SET_USER', response.data);
      } catch (error) {
        console.error('Fetch user failed', error.response ? error.response.data : error.message);
      }
    },
    
    logout({ commit }) {
      commit('LOGOUT');
    },
  },
  getters: {
    isAuthenticated(state) {
      return !!state.accessToken;
    },
    getUser(state) {
      return state.user;
    },
    googleApiKey(state) {
      return state.googleApiKey;
    },
  }
});

export default store;
